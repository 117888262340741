<template>
    <div class="gallery">
        <Carousel class="w-full slider-for">
            <Slide v-for="slide in slides" :key="slide.src" v-bind:id="slide.id">
            <div class="w-full h-full mx-auto my-1 text-white bg-white bg-opacity-10 rounded-lg overflow-hidden media_holder">
                <div class="relative h-full">
                    <div class="absolute right-4 top-4 z-10">
                        <BaseIcon class="inline float-left mx-1" v-bind:name="slide.type" bg="bg-halfColonialWhite" outline="false"/>
                        <BaseIcon class="inline float-left mx-1" @click.prevent="toggleFullscreen()" name="extendHalfColonialWhite" outline="false"/>
                    </div>
                    <div class="media_content w-full h-full max-h-screen50 lg:max-h-screen75 flex items-center justify-center relative  z-0">
                        <div v-if="slide.type=='image'" class="w-full h-full flex items-center justify-center">
                            <img class="mx-auto w-full h-full" :ref="slide.id" @load="loadedGallery()" v-bind:src="slide.src" alt="" style="object-fit:contain;">
                        </div>
                        <div v-else-if="slide.type=='media'" class="pb-20 pt-20">
                            <div :ref="'overlay' + slide.id" class="w-full h-full absolute left-0 top-0 bg-eternity bg-opacity-60 z-10 ">
                                <div :ref="'play' + slide.id" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <BaseIcon class="flex rounded-full" name="mediaPlay" outline="false" size="w-20 h-20" 
                                    @click.prevent="toggleMedia(slide.id)" />
                                </div>
                                <div :ref="'pause' + slide.id" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden">
                                    <BaseIcon class="inline float-left rounded-full mx-3 relative top-5" name="mediaMinus" outline="false" size="w-10 h-10" 
                                    @click.prevent="minusFiveSec(slide.id)" />
                                    <BaseIcon class="inline float-left rounded-full mx-3" name="mediaPause" outline="false" size="w-20 h-20" 
                                    @click.prevent="toggleMedia(slide.id)" />
                                    <BaseIcon class="inline float-left rounded-full mx-3 relative top-5" name="mediaPlus" outline="false" size="w-10 h-10" 
                                    @click.prevent="plusFiveSec(slide.id)" />
                                    <!-- <BaseIcon class="inline float-left rounded-full mx-3 relative top-5" name="mediaFullScreen" outline="false" size="w-10 h-10" 
                                    @click.prevent="fullScreenMedia(slide.id)" /> -->
                                </div>
                                <div :ref="'progressBarHolder' + slide.id" class="progressBarHolder absolute left-0 h-0.5 py-4 w-full hidden" @click.prevent="seekBarMedia ( slide.id )">
                                    <div class="left-0 h-0.5 bg-mercury w-full absolute top-1/2">
                                        <div :ref="'progressBar' + slide.id" class="absolute top-0 h-0.5 bg-redDamask" style="width:0px"></div>
                                        <div :ref="'progressBall' + slide.id" class="absolute w-4 h-4 bg-redDamask rounded-full" style="left:0; top: calc(-0.5rem + 1px);"></div>
                                    </div>
                                </div>
                            </div>
                            <video class="videos relative mx-auto z-0" :ref="slide.id" v-bind:src="slide.src" @load="loadedVideo()" @ended="endMedia(slide.id)"></video>                        
                        </div>
                        <div v-else-if="slide.type=='sound'" class="pb-20">
                            <div :ref="'overlay' + slide.id" class="w-full h-full absolute left-0 top-0 bg-eternity opacity-0 hover:opacity-100 transition-all duration-200 hover:bg-opacity-60">
                                <div :ref="'play' + slide.id" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <BaseIcon class="flex rounded-full" name="mediaPlay" outline="false" size="w-20 h-20" 
                                    @click.prevent="toggleMedia(slide.id)" />
                                </div>
                                <div :ref="'pause' + slide.id" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden">
                                    <BaseIcon class="inline float-left rounded-full mx-3 relative top-5" name="mediaMinus" outline="false" size="w-10 h-10" 
                                    @click.prevent="minusFiveSec(slide.id)" />
                                    <BaseIcon class="inline float-left rounded-full mx-3" name="mediaPause" outline="false" size="w-20 h-20" 
                                    @click.prevent="toggleMedia(slide.id)" />
                                    <BaseIcon class="inline float-left rounded-full mx-3 relative top-5" name="mediaPlus" outline="false" size="w-10 h-10" 
                                    @click.prevent="plusFiveSec(slide.id)" />
                                </div>
                                <div :ref="'progressBarHolder' + slide.id" class="absolute left-1/2 h-0.5 py-4 w-2/3 transform translate-y-20 -translate-x-1/2 hidden" @click.prevent="seekBarMedia ( slide.id )">
                                    <div class="left-0 h-0.5 bg-mercury w-full absolute top-1/2">
                                        <div :ref="'progressBar' + slide.id" class="absolute top-0 h-0.5 bg-redDamask" style="width:0px"></div>
                                        <div :ref="'progressBall' + slide.id" class="absolute w-4 h-4 bg-redDamask rounded-full" style="left:0; top: calc(-0.5rem + 1px);"></div>
                                    </div>
                                </div>
                            </div>
                            <img class="p-20 w-full mx-auto" :src="audioSrc"  alt="">
                            <audio :ref="slide.id" v-bind:src="slide.src" @ended="endMedia(slide.id)"></audio>                        
                        </div>
                        <div class="w-full h-full flex items-center justify-center" v-else-if="slide.type=='iframe'">
                            <div class="w-full relative h-0 overflow-hidden" style="padding-bottom: 56%;">
                                <object class="absolute top-0 left-0 w-full h-full" :data="embededVideo(slide.src)">
                                </object>
                            </div>
                        </div>
                    </div>
                </div>
                <div :ref="'media_description' + slide.id" class="absolute bottom-0 mx-auto w-full bg-gradient-to-b from-transparent to-sepiaBlack rounded-b-lg media_description">
                    <div class="mx-auto max-w-md text-center text-bodyS text-shadow py-6 px-9">
                        {{ slide.shortDescription }}
                    </div>
                </div>
            </div>
            </Slide>

            <template #addons="{ slidesCount }" >
            <Navigation v-if="slidesCount > 1" />
            <Pagination v-if="slidesCount > 1" />
            </template>

        </Carousel>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

import BaseIcon from '@/components/BaseIcon.vue';

var carouselNext, carouselPrev, carouselNavigation, carouselVideos;

export default defineComponent({
    name: 'Gallery',
    components: {
        Carousel,
        Slide,
        Navigation,
        BaseIcon,
        Pagination,
    },
    computed: {
        audioSrc(){
            return require("../assets/audioPlaceholder.svg");
        },
    },
    data () {
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            slides: [],
            entity_id: this.$route.params.id,
        }
    },
    mounted() {
        this.getGallery();
    },
    methods: {
        getGallery() {
            this.axios.get( this.baseURL + `/wp-json/wp/v2/entity/gallery`, {params: {entity_id: this.entity_id}}, {}).then(response => {
                this.slides = response.data;
            }).catch( (error) => {
                console.log(error);
            });
        },
        embededVideo(url){

            var urlParts;
            var videoId = "";
            
            if( url.indexOf("youtube") !== -1 ) {
                urlParts = url.split("?v=");
                videoId = urlParts[1].substring(0,11);
                return "https://www.youtube.com/embed/" + videoId;
            }else if( url.indexOf("youtu.be") !== -1 ) {
                urlParts = url.replace("//", "").split("/");    
                videoId = urlParts[1].substring(0,11);
                return "https://www.youtube.com/embed/" + videoId;
            }else{
                urlParts = url.replace("//", "").split("/");    
                videoId = urlParts[1];
                return "https://player.vimeo.com/video/" + videoId;
            }
        },
        toggleMedia ( mediaId ) {

            var pauseButtons = this.$refs['pause'+mediaId],
                playButtons = this.$refs['play'+mediaId],
                overlay = this.$refs['overlay'+mediaId],
                media = this.$refs[mediaId],
                progressBar = this.$refs['progressBar'+mediaId],
                progressBall = this.$refs['progressBall'+mediaId],
                progressBarHolder = this.$refs['progressBarHolder'+mediaId],
                media_description = this.$refs['media_description'+mediaId];
                
            progressBarHolder.style.top = 'calc(50% + ' + ((media.offsetHeight/2)-16)+'px)';
            progressBarHolder.classList.remove('hidden');

            if (media.paused) {
                media.play();
                playButtons.classList.add('hidden');
                pauseButtons.classList.remove('hidden');
                overlay.classList.remove('bg-opacity-60');
                overlay.classList.add('bg-opacity-0');
                media_description.classList.add('hidden');
                media.ontimeupdate = function(){
                    var percentage = ( media.currentTime / media.duration ) * 100;
                    progressBall.style.left = percentage+'%';
                    progressBar.style.width = percentage+'%';
                }
            } else {
                media.pause();
                playButtons.classList.remove('hidden');
                pauseButtons.classList.add('hidden');
                overlay.classList.add('bg-opacity-60');
                overlay.classList.remove('bg-opacity-0');
                media_description.classList.remove('hidden');
            }
        },
        plusFiveSec ( mediaId ) {

            var media = this.$refs[mediaId];
            if (media.played) {
                media.currentTime += 5;
            }
        },
        minusFiveSec ( mediaId ) {

            var media = this.$refs[mediaId];
            if (media.played) {
                media.currentTime -= 5;
            }
        },
        endMedia ( mediaId ) {

            var pauseButtons = this.$refs['pause'+mediaId];
            var playButtons = this.$refs['play'+mediaId];
            var overlay = this.$refs['overlay'+mediaId];
            
            playButtons.classList.remove('hidden');
            pauseButtons.classList.add('hidden');
            overlay.classList.add('bg-opacity-60');
            overlay.classList.remove('bg-opacity-0');
        },
        // fullScreenMedia ( mediaId ) {

        //     var media = this.$refs[mediaId];
        //     if (media.played) {
        //         media.webkitRequestFullScreen();
        //     }
        // },
        seekBarMedia ( mediaId ){
            var media = this.$refs[mediaId],
                medaiOffsetLeft = this.$refs['progressBarHolder' + mediaId].getBoundingClientRect().left,
                left = window.event.clientX - medaiOffsetLeft,
                totalWidth = this.$refs['progressBarHolder' + mediaId].getBoundingClientRect().width,
                percentage = ( left / totalWidth ),
                mediaTime = media.duration * percentage;
                
            media.currentTime = mediaTime;
        },
        toggleFullscreen (){
            var body = document.body,
                gallery = document.querySelector('.gallery');
            body.classList.toggle('overflow-hidden');
            gallery.classList.toggle('fullScreen');
            window.dispatchEvent(new Event('resize'));
            setVideoMaxSize();
        },
        loadedGallery () {
            this.initializeNav ();
            this.setVideoSize ();
            // var img = this.$refs[imageId],
            //     imgSize = img.getBoundingClientRect();
            
            // if (imgSize.width > imgSize.height) {
            //     img.style.width = '100%';
            //     img.style.height = '100%';
            // } else {
            //     img.style.width = '100%';
            //     img.style.height = '100%';
            // }
        },
        setVideoSize(){
             if ( carouselVideos) {
                return;
            }

            carouselVideos = document.getElementsByClassName('videos');

            if (carouselVideos) {
                
                setVideoMaxSize();
            }
        },
        initializeNav () {
            
            if ( carouselNext && carouselPrev && carouselNavigation) {
                return;
            }

            carouselNavigation = document.getElementsByClassName('carousel__pagination-button');
            if (carouselNavigation) {
                setNavBackground(carouselNavigation, this.slides)
            }
        }
    },
});

function setNavBackground(carouselNavigation, slides) {
    for (let i = 0; i < carouselNavigation.length; i++) {

        carouselNavigation[i].parentNode.classList.add(slides[i].type + 'Icon');
        if (slides[i].thumbnail){
            carouselNavigation[i].style.backgroundImage = "url('" + slides[i].thumbnail + "')";
        } else {
            carouselNavigation[i].style.backgroundColor = "rgba(253, 241, 214, 0.35)";
        }
    }
}
function setVideoMaxSize() {
    var slider = document.querySelector('.media_content');

    // var progressBarHolder = document.getElementsByClassName('progressBarHolder');

    for (let i = 0; i < carouselVideos.length; i++) {
        carouselVideos[i].style.width = slider.offsetWidth+'px';
        carouselVideos[i].style.height = slider.offsetHeight+'px';

        // progressBarHolder[i].width = carouselVideos[i].offsetWidth+'px';
    }
}
</script>

<style>
    .slider-for svg.carousel__icon {
        display: none;
    }
    .slider-for button.carousel__prev {
        background-color: transparent;
        background-image: url(../assets/icons/arrowLeftHalfColonialWhite.svg);
        width: 40px;
        height: 40px;
        left: 2.5rem;
        top: calc(50% - 4rem);
    }
    .slider-for button.carousel__next {
        background-color: transparent;
        background-image: url(../assets/icons/arrowRightHalfColonialWhite.svg);
        width: 40px;
        height: 40px;
        right: 2.5rem;
        top: calc(50% - 4rem);
    }
    .slider-nav .carousel__slide {
        opacity: 0.3;
    }
    .slider-nav .carousel__slide--active{
        opacity: 1;
    }
    .carousel__pagination {
        display: flex;
        justify-content: left;
        list-style: none;
        overflow: scroll;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .carousel__pagination::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .carousel__pagination {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .carousel__pagination-item {
        min-width: 8.375rem;
        min-height: 8.375rem;
        box-sizing: border-box;
    }
    .carousel__pagination-button {
        position: relative;
        width: 7.5rem;
        height: 7.5rem;
        opacity: 0.6;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .carousel__pagination-button:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        right: 2px;
        top: 2px;
        background: #fff;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65%;
    }
    .mediaIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/media.svg);
    }
    .imageIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/image.svg);
    }
    .soundIcon .carousel__pagination-button:after {
        background-image: url(../assets/icons/sound.svg);
    }
    
    .carousel__pagination-button--active {
        opacity: 1;
    }
    .gallery.fullScreen {
        position: fixed;
        z-index: 99;
        background: linear-gradient(180deg, #261710 43.23%, #3E2C0E 100%);
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
    }
    .fullScreen .carousel__pagination{
        justify-content: center;
    }
    .fullScreen .media_description, .fullScreen .media_holder {
        border-radius: unset;
    }
    .fullScreen .media_content{
        max-height: 85vh;
        height: 100%;
    }
</style>
